export const appendSpinnerToFormButtons = ($document: Document, cssFormSelector: string) => {
    const form = $document.querySelector(cssFormSelector)
    if (form) {
        const buttons: HTMLButtonElement[] = []
        buttons.push(...toArray(form.querySelectorAll("input[type=submit]")))
        buttons.push(...toArray(form.querySelectorAll("input[type=button]")))
        buttons.push(...toArray(form.querySelectorAll("button[type=submit]")))
        buttons.push(...toArray(form.querySelectorAll("button[type=button]")))

        form.addEventListener("submit", () => {
            disableFormButtons(buttons)
        })
        buttons.forEach((button) => {
            if (button.getAttribute("type") === "submit") {
                button.addEventListener("click", () => {
                    replaceButtonWithSpinner(button)
                })
            }
        })
    }
}

export const replaceButtonWithSpinner = (button: HTMLButtonElement) => {
    if (!button.disabled) {
        button.innerHTML = `<div class="p_loader100" style="margin: auto;"></div>`
        button.setAttribute("style", "background: none;")
    }
}

const disableFormButtons = (buttons: Element[]) => {
    buttons.forEach((btn) => {
        btn.setAttribute("disabled", "true")
    })
}

const toArray = (nodeList: NodeListOf<Element>): HTMLButtonElement[] => {
    const elements: HTMLButtonElement[] = []
    nodeList.forEach((node) => {
        elements.push(node as HTMLButtonElement)
    })
    return elements
}
